<template>
  <v-app id="app">
    <NoJs />
    <SettingsHeader />

    <v-navigation-drawer
      v-if="mobile"
      v-model="open"
      :color="color"
      id="app-nav"
      width="230"
    >
      <SettingsNavigationContent />
    </v-navigation-drawer>

    <v-main>
      <div class="mt-4 w-full md:max-w-868px lg:max-w-1074px mx-auto pa-4">
        <div class="grid grid-cols-10 gap-4">
          <div class="lt-md:hidden col-span-10 md:col-span-3">
            <v-card class="rounded-lg" border>
              <div class="pa-2 sticky top-0">
                <Workspace class="bg-[rgba(100,100,100,0.1)]!" />
              </div>
              <v-divider />
              <SettingsNavigationContent v-if="!mobile" />
            </v-card>
          </div>
          <div class="col-span-10 md:col-span-7">
            <slot></slot>
          </div>
        </div>
      </div>
    </v-main>

    <NuxtLoadingIndicator color="#5c6bc0" />
    <ConfirmDialog />
    <KeyboardShortcutsHelp />
    <Notifications />
    <!-- <AcceptTerms /> -->
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay, useTheme } from "vuetify";
import { useAppNavStore } from "~/layers/admin/stores/app-nav";
import AcceptTerms from "~/components/account-alerts/AcceptTerms.vue";

export default defineComponent({
  components: { AcceptTerms },
  setup() {
    const { mobile } = useDisplay();
    const store = useAppNavStore();
    const { open } = storeToRefs(store);
    const theme = useTheme();

    const color = computed(() =>
      theme.current.value.dark ? undefined : "grey-lighten-3"
    );

    return {
      mobile,
      color,
      open,
    };
  },
});
</script>
